import React from 'react';
import {Tag} from 'antd';

export function StockValueCustomElem(props) {
  let {value} = props;
  let CUSTOM_ELEM = null;

  if (value === 0) {
    CUSTOM_ELEM = <Tag color="blue">{value}</Tag>;
  } else if (value > 0) {
    CUSTOM_ELEM = <Tag color="green">+{value}</Tag>;
  } else if (value < 0) {
    CUSTOM_ELEM = <Tag color="red">{value}</Tag>;
  }

  return CUSTOM_ELEM;
}
