import React from 'react';
import {Button, DatePicker, Form, Select, Tag} from 'antd';
import * as Cart from 'rev.sdk.js/Actions/Cart';
import moment from 'moment';
import * as OrderUtil from 'rev.sdk.js/Utils/OrderUtil';
import * as Config from '../../../data.json';
const {RangePicker} = DatePicker;

export function OrderUiStatusCustomElem(props) {
  const {record} = props;
  try {
    const uiStatus = OrderUtil.getOrderUiStatus(record);
    const label = Cart.ORDER_UI_STATUS_DISPLAY[uiStatus];
    const ORDER_UI_STATUS_DISPLAY_ELEMENT = {
      [Cart.ORDER_UI_STATUS.unpaid]: <Tag color="#CBCBCB">{label}</Tag>,
      [Cart.ORDER_UI_STATUS.pending]: <Tag color="#E59329">{label}</Tag>,
      [Cart.ORDER_UI_STATUS.processing]: <Tag color="#d48806">{label}</Tag>,
      [Cart.ORDER_UI_STATUS.shipped]: <Tag color="#9FD000">{label}</Tag>,
      [Cart.ORDER_UI_STATUS.canceled]: <Tag color="#CBCBCB">{label}</Tag>,
      [Cart.ORDER_UI_STATUS.return_applied]: <Tag color="#D885A3">{label}</Tag>,
      [Cart.ORDER_UI_STATUS.return_completed]: (
        <Tag color="#EB5353">{label}</Tag>
      ),
      [Cart.ORDER_UI_STATUS.unknown]: <Tag color="#CBCBCB">{label}</Tag>,
    };

    return ORDER_UI_STATUS_DISPLAY_ELEMENT[uiStatus] || uiStatus;
  } catch (err) {
    console.log('err', err);
    return <div style={{color: '#CBCBCB'}}>-- --</div>;
  }
}

export function PaymentStatusCustomElem(props) {
  const {record} = props;
  try {
    const label = Cart.PAYMENT_STATUS_DISPLAY[record.payment_status].label;
    const PAYMENT_STATUS_DISPLAY_ELEMENT = {
      [Cart.PAYMENT_STATUS.pending]: <Tag color="#CBCBCB">{label}</Tag>,
      [Cart.PAYMENT_STATUS.waiting]: <Tag color="#E59329">{label}</Tag>,
      [Cart.PAYMENT_STATUS.success]: <Tag color="#9FD000">{label}</Tag>,
      [Cart.PAYMENT_STATUS.failure]: <Tag color="#FF404C">{label}</Tag>,
      [Cart.PAYMENT_STATUS.returned]: <Tag color="#C41D7F">{label}</Tag>,
      [Cart.PAYMENT_STATUS.code_generated]: <Tag color="#86C6F4">{label}</Tag>,
    };
    return PAYMENT_STATUS_DISPLAY_ELEMENT[record.payment_status];
  } catch (e) {
    return <div style={{color: '#CBCBCB'}}>-- --</div>;
  }
}

export function PaymentSubtypeCustomElem(props) {
  const {record} = props;
  let value = Cart.PAYMENT_SUBTYPE_DISPLAY[record.payment_subtype]?.label;
  return (
    <span style={value ? {color: '#CBCBCB'} : {}}>{value || '-- --'}</span>
  );
}

export function OrderStatusCustomElem(props) {
  const {record} = props;

  const ORDER_STATUS_DISPLAY_ELEMENT = {
    waiting: <div style={{color: '#767676'}}>待處理</div>,
    processing: <div style={{color: '#E59329'}}>處理中</div>,
    done: <div style={{color: '#76A801'}}>已完成</div>,
    canceled: <div style={{color: '#B5B5B5'}}>已取消</div>,
    return_applied: <div style={{color: '#D885A3'}}>退貨審核通過</div>,
    return_completed: <div style={{color: '#EB5353'}}>已退款</div>,
  };

  return (
    ORDER_STATUS_DISPLAY_ELEMENT[record.status] || (
      <div style={{color: '#CBCBCB'}}>{record.status || '---'}</div>
    )
  );
}

export function LogisticsStatusCustomElem(props) {
  const {record} = props;
  try {
    const logistics_status = record.logistics_status;
    const logistics_message = record.logistics_message;
    const label = Cart.LOGISTICS_STATUS_DISPLAY[logistics_status].label;
    const message = logistics_message || '-- --';

    const LOGISTICS_STATUS_DISPLAY_ELEMENT = {
      [Cart.LOGISTICS_STATUS.pending]: <Tag title={message}>{label}</Tag>,
      [Cart.LOGISTICS_STATUS.center_delivered]: (
        <Tag color="gold" title={message}>
          {label}
        </Tag>
      ),
      [Cart.LOGISTICS_STATUS.in_delivery]: (
        <Tag color="cyan" title={message}>
          {label}
        </Tag>
      ),
      [Cart.LOGISTICS_STATUS.store_delivered]: (
        <Tag color="geekblue" title={message}>
          {label}
        </Tag>
      ),
      [Cart.LOGISTICS_STATUS.delivered]: (
        <Tag color="green" title={message}>
          {label}
        </Tag>
      ),
      [Cart.LOGISTICS_STATUS.error]: (
        <Tag color="red" title={message}>
          {label}
        </Tag>
      ),
      [Cart.LOGISTICS_STATUS.exception]: (
        <Tag color="blue" title={message}>
          {label}
        </Tag>
      ),
    };

    return LOGISTICS_STATUS_DISPLAY_ELEMENT[logistics_status];
  } catch (e) {
    return <div style={{color: '#CBCBCB'}}>-- --</div>;
  }
}

export function LogisticsTypeCustomElem(props) {
  const {record} = props;
  let value = Cart.LOGISTICS_TYPE_DISPLAY[record.logistics_type]?.label;

  //vb custom col
  if (record.logistics_type === Cart.LOGISTICS_TYPE.custom) {
    return '海外';
  }

  return (
    <span style={value ? {color: '#CBCBCB'} : {}}>{value || '-- --'}</span>
  );
}

export function OfflineTxCustomElem(props) {
  const {record} = props;
  return (
    <div style={{fontSize: 18, fontWeight: 'bold', letterSpacing: 2}}>
      {record.offline_tx}
    </div>
  );
}

export function OrderExtraQueries(props) {
  const [form] = Form.useForm();
  const {setQueryState, queryState} = props;

  const TIME_RANGE_TYPE = {
    today: 'today',
    yesterday: 'yesterday',
    past_7_days: 'past_7_days',
    past_30_days: 'past_30_days',
    month: 'month',
    last_month: 'last_month',
    custom: 'custom',
  };

  const TIME_RANGE_TYPE_DISPLAY = {
    [TIME_RANGE_TYPE.today]: {
      label: '當天',
      value: TIME_RANGE_TYPE.today,
    },
    [TIME_RANGE_TYPE.yesterday]: {
      label: '昨天',
      value: TIME_RANGE_TYPE.yesterday,
    },
    [TIME_RANGE_TYPE.past_7_days]: {
      label: '過去 7 天',
      value: TIME_RANGE_TYPE.past_7_days,
    },
    [TIME_RANGE_TYPE.past_30_days]: {
      label: '過去 30 天',
      value: TIME_RANGE_TYPE.past_30_days,
    },
    [TIME_RANGE_TYPE.month]: {
      label: '當月',
      value: TIME_RANGE_TYPE.month,
    },
    [TIME_RANGE_TYPE.last_month]: {
      label: '上個月',
      value: TIME_RANGE_TYPE.last_month,
    },
    [TIME_RANGE_TYPE.custom]: {
      label: '自訂區間',
      value: TIME_RANGE_TYPE.custom,
    },
  };

  const getRange = (method, range) => {
    const now = moment(new Date());
    const yesterday = moment(new Date()).add(-1, 'days');
    const past7Days = moment(new Date()).add(-7, 'days');
    const past30Days = moment(new Date()).add(-30, 'days');
    const lastMonth = moment(new Date()).add(-1, 'months');
    const getBeginTimeOfDay = (_moment_date) =>
      moment(_moment_date.format('YYYY-MM-DD 00:00:00'));
    const getEndTimeOfDay = (_moment_date) =>
      moment(_moment_date.format('YYYY-MM-DD 23:59:59'));
    const getBeginDayOfMonth = (_moment_date) =>
      moment(_moment_date.format('YYYY-MM-01'));
    const getEndDayOfMonth = (_moment_date) =>
      getEndTimeOfDay(
        moment(_moment_date.add(1, 'months').format('YYYY-MM-01')).add(
          -1,
          'days',
        ),
      );
    const TIME_RANGE = {
      [TIME_RANGE_TYPE.today]: [getBeginTimeOfDay(now), now],
      [TIME_RANGE_TYPE.yesterday]: [
        getBeginTimeOfDay(yesterday),
        getEndTimeOfDay(yesterday),
      ],
      [TIME_RANGE_TYPE.past_7_days]: [getBeginTimeOfDay(past7Days), now],
      [TIME_RANGE_TYPE.past_30_days]: [getBeginTimeOfDay(past30Days), now],
      [TIME_RANGE_TYPE.month]: [getBeginDayOfMonth(now), now],
      [TIME_RANGE_TYPE.last_month]: [
        getBeginDayOfMonth(lastMonth),
        getEndDayOfMonth(lastMonth),
      ],
      [TIME_RANGE_TYPE.custom]: range,
    };
    return TIME_RANGE[method];
  };

  const onSubmit = (data) => {
    if (!data.method) {
      setQueryState((prev) => ({
        ...prev,
        extraQueries: {
          ...prev.extraQueries,
          created: undefined,
        },
      }));
      return;
    }

    const range = getRange(data.method, data.range);
    const _extraQueries = {};
    const start_date = range[0].valueOf();
    const end_date = range[1].valueOf();
    _extraQueries['created'] = {
      $gte: start_date,
      $lte: end_date,
    };

    setQueryState((prev) => {
      const nextExtraQuery = {...prev.extraQueries, ..._extraQueries};
      const filterOutQueryKeys = Object.keys(prev.extraQueries)
        .map((q) => {
          if (!Object.keys(_extraQueries).find((qk) => qk === q)) {
            return q;
          } else {
            return null;
          }
        })
        .filter((k) => !!k);
      filterOutQueryKeys.forEach((k) => {
        delete nextExtraQuery[k];
      });
      console.log({
        ...prev,
        extraQueries: nextExtraQuery,
      });
      return {
        ...prev,
        extraQueries: nextExtraQuery,
      };
    });
  };

  return (
    <Form
      form={form}
      initialValues={{
        method: null,
      }}
      colon={false}
      onFinish={onSubmit}>
      <Form.Item label="日期區間" name="method">
        <Select
          defaultValue=""
          allowClear
          style={{maxWidth: 180}}
          placeholder="請選擇日期區間">
          {Object.values(TIME_RANGE_TYPE_DISPLAY).map((type, index) => (
            <Select.Option key={index} value={type.value}>
              {type.label}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>
      <Form.Item noStyle dependencies={['method']}>
        {(form) => {
          if (form.getFieldValue('method') === TIME_RANGE_TYPE.custom) {
            return (
              <Form.Item
                rules={[{required: true, message: '請填寫查找區間'}]}
                name="range"
                label="自訂區間">
                <RangePicker showTime />
              </Form.Item>
            );
          } else {
            return null;
          }
        }}
      </Form.Item>
      <Form.Item>
        <Button htmlType="submit" type="primary">
          查詢
        </Button>
      </Form.Item>
    </Form>
  );
}

export function ReturnStatusCustomElem(props) {
  const {record} = props;

  try {
    const label = Cart.RETURN_STATUS_DISPLAY[record.status].label;
    const RETURN_STATUS_DISPLAY_ELEMENT = {
      [Cart.RETURN_STATUS.pending]: <Tag color="#8D8DAA">{label}</Tag>,
      [Cart.RETURN_STATUS.return_applied]: <Tag color="#6FB2D2">{label}</Tag>,
      [Cart.RETURN_STATUS.return_rejected]: <Tag color="#DAB88B">{label}</Tag>,
      [Cart.RETURN_STATUS.return_completed]: <Tag color="#F56D91">{label}</Tag>,
    };
    return RETURN_STATUS_DISPLAY_ELEMENT[record.status];
  } catch (e) {
    return <div style={{color: '#CBCBCB'}}>-- --</div>;
  }
}
