import React from 'react';
import * as Cart from 'rev.sdk.js/Actions/Cart';
import {Input} from 'antd';
import AdminLinkButton from '../../Generators/AdminResource/AdminLinkButton';
import VbAdminPrivateProfile from './VbAdminPrivateProfile';

export function renderCustomAdminSection(props) {
  const {name, type, context} = props;

  if (name === 'UserCustom' && context.position === 'top') {
    return [true, <VbAdminPrivateProfile context={context} />];
  }

  if (name === 'MailForm' && context.position === 'top') {
    return [
      true,
      <div style={{paddingBottom: 24}}>
        <label style={{lineHeight: '40px', paddingBottom: 8}}>寄送時間</label>
        <Input
          disabled
          value={new Date(context.instance.created).toLocaleString()}
        />
      </div>,
    ];
  }

  return [false, null];
}

export function renderCustomAdminCol(props) {
  const {col, record} = props;

  if (col.customType === 'date_time') {
    return [
      true,
      <AdminLinkButton
        title={new Date(record[col.key]).toLocaleString()}
        url={`/admin/mails/?action=detail&id=${record.id}`}
      />,
    ];
  }

  if (col.customType === 'logistics_type') {
    if (record.logistics_type === Cart.LOGISTICS_TYPE.custom) {
      return [true, '海外'];
    }

    let value = Cart.LOGISTICS_TYPE_DISPLAY[record.logistics_type]?.label;
    return [
      true,
      <span style={value ? {} : {color: '#CBCBCB'}}>{value || '-- --'}</span>,
    ];
  }

  return [false, null];
}
