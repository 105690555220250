import React from 'react';
import moment from 'moment';
import styled from 'styled-components';
import {
  Modal,
  Radio,
  Input,
  InputNumber,
  DatePicker,
  TimePicker,
  message,
} from 'antd';
import {useOutlet} from 'reconnect.js';
import * as ApiUtil from 'rev.sdk.js/Utils/ApiUtil';
import * as AppActions from '../../../AppActions';
import Config from '../../../../data.json';

const req = ApiUtil.req;

function prependTwoZero(value) {
  return ('00' + value.toString()).slice(-2);
}

function LogisticsSfModal(props) {
  const tomorrow = new Date(new Date().getTime() + 86400000);
  const [user] = useOutlet('user');
  const {visible, instance, onClose} = props;
  const [values, setValues] = React.useState({
    // platform_code: 'INT0005',
    // parcel_quantity: 1,
    // pickup_time: '2022-08-05 10:00',
    // note: 'TEST',
    platform_code: 'INT0005',
    parcel_quantity: 1,
    note: '',
  });
  const [pickup, setPickup] = React.useState({
    date: `${tomorrow.getFullYear()}-${prependTwoZero(
      tomorrow.getMonth() + 1,
    )}-${prependTwoZero(tomorrow.getDate())}`,
    time: `${prependTwoZero(tomorrow.getHours())}:${prependTwoZero(
      tomorrow.getMinutes(),
    )}`,
  });

  const createLogisticsSf = React.useCallback(async () => {
    if (!pickup.date || !pickup.time) {
      message.warning('請填寫收貨時間。');
      return;
    }

    try {
      AppActions.setLoading(true);
      let resp = await req(
        `${Config.apiHost}/logistics/create?token=${user.token}`,
        {
          method: 'POST',
          data: {
            id: instance.id,
            extra_data: {
              platform_code: values.platform_code,
              parcel_quantity: values.parcel_quantity,
              pickup_time: values.pickup_time,
              note: values.note,
            },
          },
        },
      );
      message.success('創建成功。');
      onClose();
    } catch (err) {
      console.warn(err);
      message.error('發生錯誤。');
    } finally {
      AppActions.setLoading(false);
    }
  }, [user.token, values, instance.id, onClose, pickup]);

  return (
    <Modal
      destroyOnClose={true}
      visible={visible}
      onCancel={onClose}
      title="創建 順豐物流單"
      okText="建立"
      cancelText="取消"
      onOk={createLogisticsSf}>
      <Wrapper>
        <LogisticsField>
          <label>訂單編號</label>
          <span>{instance.order_id}</span>
        </LogisticsField>

        <LogisticsField>
          <label>收件人姓名</label>
          <span>{instance.receiver_name}</span>
        </LogisticsField>

        <LogisticsField>
          <label>收件人地址</label>
          <span>
            {`${instance.receiver_zip} ${instance.receiver_district}${instance.receiver_address}`}
          </span>
        </LogisticsField>

        <LogisticsField>
          <label>國際產品映射碼</label>

          <Radio.Group
            value={values.platform_code}
            onChange={(e) =>
              setValues({...values, platform_code: e.target.value})
            }
            style={{flex: 1}}>
            <Radio value="INT0005">
              INT0005 順豐特快<span className="hint">中港澳+台灣宅配</span>
            </Radio>
          </Radio.Group>
        </LogisticsField>

        <LogisticsField>
          <label>包裹數量</label>
          <InputNumber
            value={values.parcel_quantity}
            onChange={(value) => setValues({...values, parcel_quantity: value})}
            min={1}
          />
        </LogisticsField>

        <LogisticsField>
          <label>收貨時間</label>
          <DatePicker
            placeholder="選擇日期"
            value={moment(pickup.date)}
            onChange={(date, dateString) =>
              setPickup({...pickup, date: dateString})
            }
            style={{marginRight: 10}}
          />
          <TimePicker
            placeholder="選擇時間"
            format="HH:mm"
            value={moment(pickup.time, 'HH:mm')}
            onChange={(time, timeString) =>
              setPickup({...pickup, time: timeString})
            }
          />
        </LogisticsField>

        <LogisticsField>
          <label>備註</label>
          <Input.TextArea
            value={values.note}
            onChange={(e) => setValues({...values, note: e.target.value})}
            style={{minHeight: 100}}
          />
        </LogisticsField>
      </Wrapper>
    </Modal>
  );
}

const Wrapper = styled.div``;

const LogisticsField = styled.div`
  margin-bottom: 10px;
  display: flex;
  flex-direction: ${(props) => (props.column ? 'column' : 'row')};

  & > label {
    flex-basis: ${(props) => (props.column ? 'auto' : '120px')};
    margin-right: ${(props) => (props.column ? '10px' : '0px')};
    margin-bottom: ${(props) => (props.column ? '10px' : '0px')};
    color: #4e4e4e;
  }

  .hint {
    margin-left: 10px;
    color: #adadad;
  }
}
`;

export default LogisticsSfModal;
