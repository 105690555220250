import React from 'react';
import {Button, message} from 'antd';
import {getOutlet} from 'reconnect.js';
import * as Admin from 'rev.sdk.js/Actions/Admin';
import * as AppActions from '../../AppActions';

export function MultiLogisticsCreateButton(props) {
  const {refresh} = props;
  const handleGenLogistics = React.useCallback(async () => {
    const orders = getOutlet('selected-orders').getValue();

    if (orders.length <= 0) {
      message.warning('尚未選取訂單。');
      return;
    }

    AppActions.setLoading(true, {
      message: `正在建立 1 / ${orders.length} 張物流單`,
    });

    const invalidOrders = [];
    try {
      const results = [];

      for (const order of orders) {
        if (
          order.logistics_subtype === 'SF' ||
          order.logistics_type === 'OVERSEA'
        ) {
          invalidOrders.push(order);
          continue;
        }

        const resp = await Admin.createLogistics(order.id);
        results.push({order});

        AppActions.setLoading(true, {
          message: `正在建立 ${results.length + 1} / ${orders.length} 張物流單`,
        });
      }

      message.success('批次建立成功。');
    } catch (err) {
      console.warn(err);
      message.error('批次建立失敗。');
    }

    refresh();
    AppActions.setLoading(false);

    await AppActions.delay(1000);
    if (invalidOrders.length > 0) {
      alert(
        `注意: 以下訂單不支援此操作\n${invalidOrders
          .map((o) => o.order_id)
          .join('\n')}`,
      );
    }
  }, [refresh]);

  return (
    <Button onClick={handleGenLogistics} style={{margin: 5}}>
      建立物流單
    </Button>
  );
}
