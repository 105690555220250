import React from 'react';
import styled from 'styled-components';
import {Button} from 'antd';
import {
  PlusCircleOutlined,
  MinusCircleOutlined,
  ClearOutlined,
  SettingOutlined,
} from '@ant-design/icons';
import {showAdminProductStockModal} from '../../Components/AdminProductStockModal';

const BUTTON_PROPS = {
  stock_add_action: {
    shape: 'round',
    type: 'primary',
    icon: <PlusCircleOutlined />,
    label: '進貨',
  },
  stock_subtract_action: {
    shape: 'round',
    icon: <MinusCircleOutlined />,
    label: '銷貨',
  },
  stock_reset_action: {
    shape: 'round',
    type: 'dashed',
    danger: true,
    icon: <ClearOutlined />,
    label: '清零',
  },
  stock_set_action: {
    shape: 'circle',
    type: 'link',
    icon: <SettingOutlined />,
    label: '',
  },
};

function AdminProductStockAction(props) {
  const {record, refresh} = props;

  return (
    <Wrapper>
      {Object.keys(BUTTON_PROPS).map((key, index) => (
        <Button
          {...BUTTON_PROPS[key]}
          style={{
            margin: 5,
            marginRight:
              index + 1 !== Object.keys(BUTTON_PROPS).length ? '5px' : '0px',
          }}
          onClick={() =>
            showAdminProductStockModal({
              action: key,
              record,
              refresh,
            })
          }>
          {BUTTON_PROPS[key].label}
        </Button>
      ))}
    </Wrapper>
  );
}

const Wrapper = styled.div``;

export default AdminProductStockAction;
