import {getOutlet, hasOutlet} from 'reconnect.js';

export function getFlattenCategories(outletKey) {
  if (!hasOutlet(outletKey)) {
    return [];
  }

  const cats = getOutlet(outletKey).getValue();
  const result = [];

  if (Array.isArray(cats)) {
    for (const c1 of cats) {
      result.push(c1);
      if (Array.isArray(c1.items)) {
        for (const c2 of c1.items) {
          result.push(c2);
          if (Array.isArray(c2.items)) {
            for (const c3 of c2.items) {
              result.push(c3);
            }
          }
        }
      }
    }
  }

  return result;
}
