import * as CatUtil from '../../Utils/CatUtil';

export function handleConfigs({path, pageContext}) {
  if (path.indexOf('/admin/combine-discount') > -1) {
    const cats = CatUtil.getFlattenCategories('categories');

    if (pageContext.resource.formSpec.schema.properties.combine_discount) {
      let catsL2 = cats.filter((cat) => cat.name.split('-').length === 2);

      pageContext.resource.formSpec.schema.properties.combine_discount.items.properties.labels.items = {
        type: 'string',
        enum: catsL2.map((cat) => cat.name),
        enumNames: catsL2.map((cat) => `${cat.display} ${cat.name}`),
      };
    }
  }
}
