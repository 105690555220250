import React from 'react';
import * as AppActions from '../../AppActions';
import {Button} from 'antd';

export default function AdminLinkButton(props) {
  const {url, title} = props;
  return (
    <Button
      type="link"
      href={url}
      onClick={(e) => {
        e.preventDefault();
        AppActions.navigate(url);
      }}>
      {title}
    </Button>
  );
}
