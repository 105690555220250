import React from 'react';
import {Button} from 'antd';
const qs = require('query-string');

export default function AdminPageBuilderButtonAction(props) {
  const {location} = props;
  const {id} = qs.parse(location.search);

  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'flex-end',
        marginTop: 10,
        position: 'sticky',
        bottom: 20,
      }}>
      <Button
        type="primary"
        href={`/page-preview/?id=${id}`}
        target="_blank"
        style={{boxShadow: '0px 0px 6px 2px var(--primaryColor)'}}>
        立即查看
      </Button>
    </div>
  );
}
