import React from 'react';
import moment from 'moment';
import styled from 'styled-components';
import {Modal, Input, InputNumber, DatePicker, TimePicker, message} from 'antd';
import {useOutlet} from 'reconnect.js';
import * as ApiUtil from 'rev.sdk.js/Utils/ApiUtil';
import * as AppActions from '../../../AppActions';
import Config from '../../../../data.json';

const req = ApiUtil.req;

function prependTwoZero(value) {
  return ('00' + value.toString()).slice(-2);
}

export default function LogisticsSfOverseaModal(props) {
  const tomorrow = new Date(new Date().getTime() + 86400000);
  const [user] = useOutlet('user');
  const {visible, instance, onClose} = props;
  const [values, setValues] = React.useState({
    parcel_quantity: 1,
    note: '',
    ...(instance?.extra_data || {}),
  });
  const [pickup, setPickup] = React.useState({
    date: `${tomorrow.getFullYear()}-${prependTwoZero(
      tomorrow.getMonth() + 1,
    )}-${prependTwoZero(tomorrow.getDate())}`,
    time: `${prependTwoZero(tomorrow.getHours())}:${prependTwoZero(
      tomorrow.getMinutes(),
    )}`,
  });

  const createLogisticsSf = React.useCallback(async () => {
    if (!pickup.date || !pickup.time) {
      message.warning('請填寫收貨時間。');
      return;
    }

    try {
      AppActions.setLoading(true);

      const platformCodeMap = {
        CN: 'INT0005',
        MO: 'INT0005',
        HK: 'INT0005',
      };

      const extraData = {
        platform_code: platformCodeMap[instance.logistics_subtype],
        parcel_quantity: values.parcel_quantity,
        pickup_time: `${pickup.date} ${pickup.time}`,
        note: values.note,
        receiver_name: values.receiver_name,
        receiver_phone: values.receiver_phone,
        receiver_email: values.receiver_email,
        receiver_post_code: values.receiver_post_code,
        receiver_region_first: values.receiver_region_first,
        receiver_region_second: values.receiver_region_second,
        receiver_region_third: values.receiver_region_third,
        receiver_address: values.receiver_address,
      };

      await req(`${Config.apiHost}/logistics/create?token=${user.token}`, {
        method: 'POST',
        data: {
          id: instance.id,
          extra_data: extraData,
        },
      });
      message.success('創建成功。');
      onClose();
    } catch (err) {
      console.warn(err);
      message.error('發生錯誤。');
    } finally {
      AppActions.setLoading(false);
    }
  }, [user.token, values, instance, onClose, pickup]);

  const getInputProps = (field, {placeholder = ''} = {}) => {
    return {
      placeholder,
      defaultValue: values[field] || '',
      onChange: (e) => {
        setValues({
          ...values,
          [field]: e.target.value,
        });
      },
    };
  };

  return (
    <Modal
      destroyOnClose={true}
      visible={visible}
      onCancel={onClose}
      title="創建 海外順豐物流單"
      okText="建立"
      cancelText="取消"
      onOk={createLogisticsSf}>
      <Wrapper>
        <LogisticsField>
          <label>訂單編號</label>
          <span>{instance.order_id}</span>
        </LogisticsField>

        <LogisticsField>
          <label>包裹數量</label>
          <InputNumber
            value={values.parcel_quantity}
            onChange={(value) => setValues({...values, parcel_quantity: value})}
            min={1}
          />
        </LogisticsField>

        <LogisticsField>
          <label>收貨時間</label>
          <DatePicker
            placeholder="選擇日期"
            value={moment(pickup.date)}
            onChange={(date, dateString) => {
              console.log({date, dateString});
              setPickup({...pickup, date: dateString});
            }}
            style={{marginRight: 10}}
          />
          <TimePicker
            placeholder="選擇時間"
            format="HH:mm"
            value={moment(pickup.time, 'HH:mm')}
            onChange={(time, timeString) => {
              console.log({time, timeString});
              setPickup({...pickup, time: timeString});
            }}
          />
        </LogisticsField>

        <LogisticsField>
          <label>收件人姓名</label>
          <Input {...getInputProps('receiver_name')} />
        </LogisticsField>

        <LogisticsField>
          <label>收件人手機</label>
          <Input {...getInputProps('receiver_phone')} />
        </LogisticsField>

        <LogisticsField>
          <label>收件人信箱</label>
          <Input {...getInputProps('receiver_email')} />
        </LogisticsField>

        <LogisticsField>
          <label>郵遞區號</label>
          <Input {...getInputProps('receiver_post_code')} />
        </LogisticsField>

        <LogisticsField>
          <label style={{display: 'block', marginBottom: 5}}>一級行政區</label>
          <Input {...getInputProps('receiver_region_first')} />
        </LogisticsField>

        <LogisticsField>
          <label style={{display: 'block', marginBottom: 5}}>二級行政區</label>
          <Input {...getInputProps('receiver_region_second')} />
        </LogisticsField>

        <LogisticsField>
          <label style={{display: 'block', marginBottom: 5}}>三級行政區</label>
          <Input {...getInputProps('receiver_region_third')} />
        </LogisticsField>

        <LogisticsField>
          <label style={{display: 'block', marginBottom: 5}}>地址</label>
          <Input.TextArea {...getInputProps('receiver_address')} />
        </LogisticsField>

        <LogisticsField>
          <label>備註</label>
          <Input.TextArea
            value={values.note}
            onChange={(e) => setValues({...values, note: e.target.value})}
            style={{minHeight: 100}}
          />
        </LogisticsField>
      </Wrapper>
    </Modal>
  );
}

const Wrapper = styled.div``;

const LogisticsField = styled.div`
  margin-bottom: 10px;
  display: flex;
  flex-direction: ${(props) => (props.column ? 'column' : 'row')};

  & > label {
    flex-basis: ${(props) => (props.column ? 'auto' : '120px')};
    margin-right: ${(props) => (props.column ? '10px' : '0px')};
    margin-bottom: ${(props) => (props.column ? '10px' : '0px')};
    color: #4e4e4e;
  }

  .hint {
    margin-left: 10px;
    color: #adadad;
  }
}
`;
