import React from 'react';
import {Button, message} from 'antd';
import * as AppActions from '../../AppActions';
import Config from '../../../data.json';

export default function ProductShareButton(props) {
  const {context} = props;

  let _copyShareUrl = async () => {
    let _data = context.instance;
    let _url = AppActions.getReurl({
      title: encodeURIComponent(_data.name),
      image: _data.og_image || '',
      redirectUrl: `${Config.webHost}/product?id=${_data.id}`,
    });
    try {
      await navigator.clipboard.writeText(_url);
      message.success(`已複製分享連結 ${_url}`);
    } catch (err) {
      console.log(err);
      message.warn(`無法複製連結 ${_url}`);
    }
  };

  return <Button onClick={_copyShareUrl}>取得分享連結</Button>;
}
