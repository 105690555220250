import React from 'react';
import {getOutlet} from 'reconnect.js';
import styled from 'styled-components';
import {
  MutltiLogisticsStatusButton,
  MutltiOrderStatusButton,
} from './AdminMultiOrderButton';
import {MultiLogisticsCreateButton} from './AdminMultiLogisticsCreateButton';
import {MultiShippingNoteDownloadPdfButton} from './AdminMultiShippingNoteDownloadPdfButton';

export function handleOrdersBulkWrite({path, pageContext}) {
  if (path.indexOf('/admin/orders/bulk-write') > -1) {
    pageContext.resource = {
      ...pageContext.resource,
      resourceTableProps: {
        scroll: {
          x: 1300,
        },
        rowSelection: {
          onChange: (_, selectedRows) => {
            const orders = selectedRows;
            getOutlet('selected-orders').update([...orders]);
          },
          getCheckboxProps: (order) => {
            return {
              disabled: false,
            };
          },
        },
      },
      renderCreateButton: ({clientCallbacks}) => {
        return (
          <Wrapper>
            <MutltiOrderStatusButton refresh={clientCallbacks.refresh} />
            <MutltiLogisticsStatusButton refresh={clientCallbacks.refresh} />
            <MultiLogisticsCreateButton refresh={clientCallbacks.refresh} />
            <MultiShippingNoteDownloadPdfButton />
          </Wrapper>
        );
      },
    };
  }
}

const Wrapper = styled.div`
  flex: 1;
  display: flex;
  justify-content: flex-end;
  flex-wrap: wrap;
`;
