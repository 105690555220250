import React from 'react';
import AdminLinkButton from './AdminLinkButton';
import Config from '../../../data.json';

export default function AdminSiteConfigButton(props) {
  const {record} = props;
  return (
    <AdminLinkButton
      title={Config.siteConfig[record.name]?.display || record.name}
      url={`/admin/${record.name}?action=detail&id=${record.id}`}
    />
  );
}
