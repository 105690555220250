import React from 'react';
import {
  Document,
  Font,
  Page,
  PDFDownloadLink,
  StyleSheet,
  Text,
  View,
} from '@react-pdf/renderer';
import * as Cart from 'rev.sdk.js/Actions/Cart';
import _ from 'lodash';
// import PdfCalculationTable from './PdfCalculationTable';
// import PdfCartItemTable, {
//   getQuantity,
//   parseProductConfig,
// } from './PdfCartItemTable';

Font.register({
  family: 'MiSans',
  src: '/fonts/MiSans-Normal.ttf',
});

Font.registerHyphenationCallback((word) => {
  if (word.length === 1) {
    return [word];
  }

  return Array.from(word)
    .map((char) => [char, ''])
    .reduce((arr, current) => {
      arr.push(...current);
      return arr;
    }, []);
});

// Create styles
const styles = StyleSheet.create({
  page: {
    fontFamily: 'MiSans',
    flexDirection: 'column',
    backgroundColor: '#ffffff',
    padding: 40,
  },
  title: {
    textAlign: 'center',
    marginBottom: 10,
  },
  form: {
    flexDirection: 'column',
    marginBottom: 10,
  },
  bgColorRow: {
    backgroundColor: '#f3f3f3',
    textAlign: 'center',
    borderColor: '#b9b9b9',
    borderWidth: 1,
    paddingHorizontal: 5,
    paddingVertical: 5,
    fontSize: 10,
  },
  cell: {
    flex: 1,
    borderColor: '#b9b9b9',
    borderWidth: 1,
    paddingHorizontal: 5,
    paddingVertical: 0,
    fontSize: 10,
  },
  cellBig: {
    flex: 2,
    borderColor: '#b9b9b9',
    borderWidth: 1,
    paddingHorizontal: 5,
    paddingVertical: 0,
    fontSize: 10,
  },
  cellBigger: {
    flex: 5,
    borderColor: '#b9b9b9',
    borderWidth: 1,
    paddingHorizontal: 5,
    paddingVertical: 0,
    fontSize: 10,
  },
  cellBiggest: {
    flex: 10,
    borderColor: '#b9b9b9',
    borderWidth: 1,
    paddingHorizontal: 5,
    paddingVertical: 0,
    fontSize: 10,
  },
  row: {
    flexDirection: 'row',
  },
  info: {
    fontSize: 10,
    lineHeight: 2,
    whiteSpace: 'nowrap',
  },
});

// Create Document Component
const CustomOrderPdf = (props) => {
  let {order, productSpec} = props;

  let information = {
    name: '微媞網購商城',
    fullname: '微媞生技股份有限公司',
    email: 'contact@vbeautygroup.com',
    tel: '02-2736-6566',
    gui_number: '00000000',
  };

  let INFO = `微媞網購商城 團隊不斷地追求更好品質給我們顧客。若您有退換貨的需求，請撥打客服專線 02-8771-8830 或寫信至 contact@vbeautygroup.com 聯繫我們，我們非常樂意在商品抵達後七日內為您提供退換貨服務。依消費者保護法規定，商品未經拆封使用、非人為瑕疵造成污損及故障或其他任何因素退貨等，商品必須恢復至您收到商品時的原始狀態且包裝完整(保持產品、贈品、附件、包裝、紙箱及所有相關文件、資料之完整性)。

  請注意！
  申請退換貨服務以商品瑕疵、出貨有誤為主，其餘情況運費需由買家自行負擔。基於衛生因素考量，個人用品拆封後恕無接受退換貨服務，敬請見諒。`;
  let CONTACT = `客服專線：02-8771-8830 服務時間：週一至週五09:30-18:30`;

  function formatDate(date) {
    try {
      if (isNaN(date)) {
        throw new Error();
      }
      let value = date;

      // timestamp digit < 13, not millisecond
      if (date.toString().length < 13) {
        value = date * 1000;
      }

      // timezone offset;
      const offset = new Date().getTimezoneOffset() * 60000;
      const str = new Date(value - offset).toISOString();

      // formating YYYY-MM-DD HH:mm
      return str.slice(0, 16).replace('T', ' ');
    } catch (error) {
      return '-';
    }
  }

  return (
    <Document>
      <Page size="A4" style={styles.page}>
        <Text style={styles.title}>{information.name} 訂單明細</Text>

        <View style={styles.form}>
          <Text style={styles.bgColorRow}>訂單資訊</Text>
          <View style={styles.row}>
            <Text style={[styles.cell, {borderTop: 0, borderRight: 0}]}>
              訂單編號
            </Text>
            <Text style={[styles.cellBig, {borderTop: 0, borderRight: 0}]}>
              {order.order_id}
            </Text>
            <Text style={[styles.cell, {borderTop: 0, borderRight: 0}]}>
              訂單日期
            </Text>
            <Text style={[styles.cellBig, {borderTop: 0}]}>
              {formatDate(order.created)}
            </Text>
          </View>
          <View style={styles.row}>
            <Text style={[styles.cell, {borderTop: 0, borderRight: 0}]}>
              收件人
            </Text>
            <Text style={[styles.cellBig, {borderTop: 0, borderRight: 0}]}>
              {order.buyer_name}
            </Text>
            <Text style={[styles.cell, {borderTop: 0, borderRight: 0}]}>
              行動電話
            </Text>
            <Text style={[styles.cellBig, {borderTop: 0}]}>
              {order.buyer_phone}
            </Text>
          </View>
          <View style={styles.row}>
            <Text style={[styles.cell, {borderTop: 0, borderRight: 0}]}>
              電子信箱
            </Text>
            <Text style={[styles.cellBig, {borderTop: 0, borderRight: 0}]}>
              {order.buyer_email}
            </Text>
            <Text style={[styles.cell, {borderTop: 0, borderRight: 0}]}>
              地址
            </Text>
            {!!order.extra_data.oversea_address ? (
              <Text style={[styles.cellBig, {borderTop: 0}]}>
                {order.extra_data.oversea_address}
              </Text>
            ) : (
              <Text style={[styles.cellBig, {borderTop: 0}]}>
                {order.buyer_city} {order.buyer_district}
                {order.buyer_address}
              </Text>
            )}
          </View>
          <View style={styles.row}>
            <Text style={[styles.cell, {borderTop: 0, borderRight: 0}]}>
              付款方式
            </Text>
            <Text style={[styles.cellBig, {borderTop: 0, borderRight: 0}]}>
              {Cart.PAYMENT_SUBTYPE_DISPLAY[order.payment_subtype].label}
            </Text>
            <Text style={[styles.cell, {borderTop: 0, borderRight: 0}]}>
              取貨方式
            </Text>
            <Text style={[styles.cellBig, {borderTop: 0}]}>
              {Cart.LOGISTICS_TYPE_DISPLAY[order.logistics_type].label}
            </Text>
          </View>
        </View>

        <View style={styles.form}>
          <View style={[styles.row, {padding: 0, backgroundColor: '#f3f3f3'}]}>
            <Text style={[styles.cell, {borderRight: 0, textAlign: 'center'}]}>
              品項
            </Text>
            <Text
              style={[
                styles.cellBig,
                {borderRight: 0, textAlign: 'center', flex: 4},
              ]}>
              商品名稱
            </Text>
            <Text style={[styles.cell, {borderRight: 0, textAlign: 'center'}]}>
              價格
            </Text>
            <Text style={[styles.cell, {borderRight: 0, textAlign: 'center'}]}>
              數量
            </Text>
            <Text
              style={[styles.cellBig, {borderRight: 0, textAlign: 'center'}]}>
              加購
            </Text>
            <Text style={styles.cell}>小計(含稅)</Text>
          </View>

          {order.items.map((orderItem, idx) => (
            <View style={styles.row}>
              <Text
                style={[
                  styles.cell,
                  {borderTop: 0, borderRight: 0, textAlign: 'center'},
                ]}>
                {idx + 1}
              </Text>
              <Text
                style={[
                  styles.cellBig,
                  {borderTop: 0, borderRight: 0, flex: 4},
                ]}>
                {orderItem.name}
              </Text>
              <Text style={[styles.cell, {borderTop: 0, borderRight: 0}]}>
                {orderItem.product.price}
              </Text>
              <View
                style={[
                  styles.cell,
                  {borderTop: 0, borderRight: 0, flexDirection: 'column'},
                ]}>
                <Text style={[styles.text]}>{orderItem.config.qty}</Text>
              </View>
              <View
                style={[
                  styles.cellBig,
                  {borderTop: 0, borderRight: 0, flexDirection: 'column'},
                ]}>
                {orderItem.config.variants.map((v, idx) => {
                  let hit_variant = orderItem.product.variants.find(
                    (variant) => variant.name === v.name,
                  );
                  let hit_choice = hit_variant.choices.find(
                    (choice) => choice.name === v.choice,
                  );
                  let display_label = _.chunk(hit_choice.label, 9).map(
                    (c, i) => (
                      <Text key={i} style={[styles.text]}>
                        {c}
                      </Text>
                    ),
                  );
                  return display_label;
                })}
              </View>

              <Text style={[styles.cell, {borderTop: 0}]}>
                ${orderItem.amount}
              </Text>
            </View>
          ))}

          <View style={styles.row}>
            <Text style={[styles.cellBiggest, {borderTop: 0, borderRight: 0}]}>
              商品小計(含稅)
            </Text>
            <Text style={[styles.cell, {borderTop: 0}]}>${order.subtotal}</Text>
          </View>
          {order.discount_items.map((discount, idx) => (
            <View style={styles.row}>
              <Text
                style={[styles.cellBiggest, {borderTop: 0, borderRight: 0}]}>
                {discount.name}
              </Text>
              <Text style={[styles.cell, {borderTop: 0}]}>
                - ${discount.amount}
              </Text>
            </View>
          ))}
          <View style={styles.row}>
            <Text style={[styles.cellBiggest, {borderTop: 0, borderRight: 0}]}>
              運費
            </Text>
            <Text style={[styles.cell, {borderTop: 0}]}>${order.fee}</Text>
          </View>
          {/* <View style={styles.row}>
            <Text style={[styles.cellBigger, {borderTop: 0, borderRight: 0}]}>
              紅利(含稅)
            </Text>
            <Text style={[styles.cell, {borderTop: 0}]}>
              ${cart.calculations.bonus + cart.calculations.bonus_tax}
            </Text>
          </View> */}
          {/* <View style={styles.row}>
            <Text style={[styles.cellBigger, {borderTop: 0, borderRight: 0}]}>
              折扣(含稅)
            </Text>
            <Text style={[styles.cell, {borderTop: 0}]}>
              ${cart.calculations.discount + cart.calculations.discount_tax}
            </Text>
          </View> */}
          <View style={styles.row}>
            <Text style={[styles.cellBiggest, {borderTop: 0, borderRight: 0}]}>
              總計(含稅)
            </Text>
            <Text style={[styles.cell, {borderTop: 0}]}>${order.total}</Text>
          </View>
        </View>

        {/* <View style={styles.form}>
          <Text style={styles.bgColorRow}>商店資訊</Text>
          <View style={styles.row}>
            <Image
              style={[
                {width: 120, height: 80, objectFit: 'contain', padding: 20},
              ]}
              source={{
                uri: '/images/logo.png',
              }}
            />
            <View style={{flex: 3}}>
              <View style={styles.row}>
                <Text style={[styles.cell, {borderTop: 0, borderRight: 0}]}>
                  公司抬頭:
                </Text>
                <Text style={[styles.cellBig, {borderTop: 0}]}>
                  {information.fullname}
                </Text>
              </View>
              <View style={styles.row}>
                <Text style={[styles.cell, {borderTop: 0, borderRight: 0}]}>
                  統一編號:
                </Text>
                <Text style={[styles.cellBig, {borderTop: 0}]}>
                  {information.gui_number}
                </Text>
              </View>
              <View style={styles.row}>
                <Text style={[styles.cell, {borderTop: 0, borderRight: 0}]}>
                  聯絡電話:
                </Text>
                <Text style={[styles.cellBig, {borderTop: 0}]}>
                  {information.tel}
                </Text>
              </View>
              <View style={styles.row}>
                <Text style={[styles.cell, {borderTop: 0, borderRight: 0}]}>
                  電子信箱:
                </Text>
                <Text style={[styles.cellBig, {borderTop: 0}]}>
                  {information.email}
                </Text>
              </View>
            </View>
          </View>
        </View> */}
        <Text style={{fontSize: 14}}>退換貨須知：</Text>

        <View style={{marginTop: 15}}>
          <Text style={styles.info}>{INFO}</Text>
        </View>

        <View style={{marginTop: 5}}>
          <Text style={styles.info}>{CONTACT}</Text>
        </View>
      </Page>
    </Document>
  );
};
const CustomOrderPdfDownload = (props) => {
  // const cart = JSON.parse(props.order.cart);
  return (
    <div>
      <PDFDownloadLink
        style={props.style}
        document={<CustomOrderPdf {...props} />}
        fileName={`${props.order.id}-購物車-揀貨單.pdf`}>
        {({blob, url, loading, error}) =>
          loading ? '下載中...' : '匯出揀貨單'
        }
      </PDFDownloadLink>
    </div>
  );
};

export {CustomOrderPdfDownload};
export default CustomOrderPdf;
