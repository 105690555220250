import React from 'react';
import {Button, message, InputNumber, Input} from 'antd';
import styled from 'styled-components';
import {MoneyDollarCircle} from '@styled-icons/remix-line/MoneyDollarCircle';
import * as JStorage from 'rev.sdk.js/Actions/JStorage';
import * as Admin from 'rev.sdk.js/Actions/Admin';

function PrivateProfile(props) {
  const [privateProfile, setPrivateProfile] = React.useState(null);
  const [points, setPoints] = React.useState(0);

  let {context} = props;
  let {instance} = context;

  React.useEffect(() => {
    async function getUserPrivateProfile() {
      let resp = await JStorage.fetchOneDocument('private_profile', {
        owner: instance.owner,
      });

      setPrivateProfile(resp);
      setPoints(resp.points);
    }
    try {
      getUserPrivateProfile();
    } catch (ex) {
      console.warn(ex);
    }
  }, [instance.owner]);

  async function editUserPrivateProfile() {
    try {
      let resp = await Admin.editUserPrivateProfile(instance.owner, points);
      message.success('儲存成功！');
    } catch (ex) {
      console.warn(ex);
    }
  }

  if (privateProfile) {
    return (
      <div>
        <FieldWrapper css="background-color: #f0f0f0; margin-top: 24px; padding: 20px;">
          <MoneyDollarCircle
            color="#FFD306"
            size={20}
            style={{marginRight: 5}}
          />
          <label style={{marginRight: 10}}>紅利點數</label>

          <InputNumber
            min={0}
            value={points}
            onChange={(value) => setPoints(value)}
            style={{marginRight: 15}}
          />

          <Button type="primary" onClick={() => editUserPrivateProfile()}>
            儲存紅利
          </Button>
        </FieldWrapper>

        <FieldWrapper>
          <label>登入方式</label>
          <Input value={privateProfile.provider} disabled />
        </FieldWrapper>
      </div>
    );
  }
  return null;
}

const FieldWrapper = styled.div`
  margin-bottom: 24px;

  & > label {
    line-height: 40px;
    padding-bottom: 8px;
  }

  ${(props) => props.css || ''};
`;

export default PrivateProfile;
