import React from 'react';
import {get as SdkConfig} from 'rev.sdk.js/Config';
import * as ApiUtil from 'rev.sdk.js/Utils/ApiUtil';
import {getOutlet} from 'reconnect.js';
import {Image, View, Text} from '@react-pdf/renderer';

export const shippingNoteImgSize = {
  width: 600 * 1.5,
  height: 800 * 1.5,
};

export function getShippingNoteId(order) {
  const shippingNoteId = Array.isArray(order?.logistics)
    ? order.logistics[order.logistics.length - 1]?.logistics_id
    : null;
  return shippingNoteId;
}

const getPdfDataUrl = async (pdfUrl) => {
  return new Promise((resolve, reject) => {
    if (!window.pdfjsLib) {
      reject(new Error('no pdfjslib'));
      return;
    }

    const loadingTask = window.pdfjsLib.getDocument(pdfUrl);
    loadingTask.promise
      .then((pdf) => {
        return pdf.getPage(1).then((page) => {
          let canvas = document.createElement('canvas');
          let context = canvas.getContext('2d');
          let viewport = page.getViewport({scale: 2});

          canvas.width = viewport.width;
          canvas.height = viewport.height;

          return page
            .render({
              canvasContext: context,
              viewport: viewport,
            })
            .promise.then(() => {
              resolve(canvas.toDataURL());
            });
        });
      })
      .catch(reject);
  });
};

const getDataUrl = async (imgUrl) => {
  return new Promise((resolve, reject) => {
    const imgTag = document.createElement('img');
    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d');

    imgTag.src = imgUrl;
    imgTag.onload = () => {
      const maxSize = {
        width: shippingNoteImgSize.width,
        height: shippingNoteImgSize.height,
      };
      const pdfRadio = maxSize.height / maxSize.width;
      const imgRatio = imgTag.height / imgTag.width;
      if (imgRatio > pdfRadio) {
        // taller, respect height
        canvas.height = maxSize.height;
        canvas.width = maxSize.height / imgRatio;
      } else {
        // wider, respect width
        canvas.width = maxSize.width;
        canvas.height = maxSize.width * imgRatio;
      }
      ctx.drawImage(imgTag, 0, 0, canvas.width, canvas.height);
      resolve(canvas.toDataURL());
    };
    imgTag.onerror = (e) => {
      console.log('img load error', e);
      reject();
    };
  });
};

export async function prepareShippingNote(order) {
  let result = false;
  let fileExt = null;
  let objUrl = null;
  let dataUrl = null;

  try {
    const shippingNoteId = getShippingNoteId(order);
    const user = getOutlet('user').getValue();

    if (!shippingNoteId) {
      throw new Error('no shipingNoteId');
    } else if (!user) {
      throw new Error('invalid user');
    }

    const {url} = await ApiUtil.req(
      `${SdkConfig().storageHost}/storage/read?client_id=${
        SdkConfig().clientId
      }&token=${user.token}`,
      {
        method: 'POST',
        data: {filename: `shipping_note/${shippingNoteId}`},
      },
    );

    const resp = await fetch(url);
    const contentType = resp.headers.get('content-type');
    fileExt = contentType.split(';')[0].split('/')[1] || '';
    const blob = await resp.blob();
    const blobWithType = new Blob([blob], {type: contentType});
    objUrl = URL.createObjectURL(blobWithType);

    dataUrl = null;
    if (fileExt === 'pdf') {
      dataUrl = await getPdfDataUrl(objUrl);
    } else if (fileExt === 'html') {
      dataUrl = await parse711DataFromHtmlStr(await blob.text());
    } else {
      dataUrl = await getDataUrl(objUrl);
    }

    result = true;
  } catch (ex) {
    console.warn('prepareShippingNote', ex);
  }

  return {
    result,
    ext: fileExt,
    url: objUrl,
    data: dataUrl,
  };
}

async function parse711DataFromHtmlStr(html) {
  const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));
  const sendQrDivId = 'rev-send-qrcode-div';
  const qrDivId = 'rev-qrcode-div';

  if (!window.JsBarcode) {
    throw new Error('cannot find JsBarcode');
  } else if (!window.QRCode) {
    throw new Error('cannot find QRCode');
  }

  const wrapperDiv = document.createElement('div');
  const fields = [];
  const imgFields = [];

  wrapperDiv.innerHTML =
    html.slice(html.indexOf('<table'), html.lastIndexOf('</table>')) +
    '</table>';
  document.body.appendChild(wrapperDiv);

  // extract simply string data
  for (const item of [
    {id: 'lblC2BPinCode1', display: '交貨便服務代碼'},
    {id: 'lblRecvshop1', display: '取件門市'},
    {id: 'lblRecver1', display: '取件人'},
    {id: 'lblBarCode4', display: '物流專用條碼'},
    {id: 'lblDeadDateTime1', display: '繳費期限'},
    {id: 'lblPaymentCPName1', display: '廠商名稱'},
    {id: 'lblOrderNo', display: '寄件訂單編號'},
    {id: 'lblShopperName1', display: '寄件人'},
  ]) {
    fields.push({
      label: item.display,
      value: document.querySelector(`#${item.id}`)?.innerText,
    });
  }

  // generate 2D qrcodes, instead legacy 1D barcodes
  const sendQrDiv = document.createElement('div');
  sendQrDiv.id = sendQrDivId;
  document.body.appendChild(sendQrDiv);
  const sendQrcode = new window.QRCode(sendQrDivId);
  const sendQrcodeSrc = document
    .querySelector('img#sendQRCode')
    ?.src.split('SendQRCode.ashx?')[1]
    .split('&')
    .map((str) => str.slice(6));

  const sendQrValue = JSON.stringify({
    Utility: [
      {
        Ordinary: [
          {
            Device: 'POS',
            code1: sendQrcodeSrc[0],
            code2: sendQrcodeSrc[1],
            code3: sendQrcodeSrc[2],
          },
        ],
      },
    ],
  });
  sendQrcode.makeCode(sendQrValue);
  await delay(200);
  imgFields.push({
    label: 'SendQRCODE',
    value: sendQrValue,
    data: document.querySelector(`#${sendQrDivId} img`).src,
  });

  // generate 2D qrcodes
  const qrDiv = document.createElement('div');
  qrDiv.id = qrDivId;
  document.body.appendChild(qrDiv);
  const qrcode = new window.QRCode(qrDivId);
  const qrValue = document
    .querySelector(`img#QRCODE`)
    ?.src.split('CodeValue=')[1];
  qrcode.makeCode(qrValue);
  await delay(200); // because QRCode lib generates code in async
  imgFields.push({
    label: 'QRCODE',
    value: qrValue,
    data: document.querySelector(`#${qrDivId} img`).src,
  });

  // remove appended DOM elems
  sendQrDiv.remove();
  qrDiv.remove();
  wrapperDiv.remove();

  return {
    is711: true,
    fields,
    imgFields,
  };
}

export function PdfView711(props) {
  const {
    data: {fields, imgFields},
  } = props;

  return (
    <View
      style={{
        margin: 10,
        paddingTop: 20,
        paddingBottom: 35,
        paddingHorizontal: 10,
        width: 400,
        borderWidth: 2,
        borderColor: 'black',
        alignItems: 'center',
      }}>
      <Text style={{fontSize: 12, marginBottom: 10}}>
        統一超商 交貨便 服務單
      </Text>
      <Image
        style={{width: 120, height: 120, marginTop: 25, marginBottom: 25}}
        source={{uri: imgFields[0].data}}
      />

      <View
        style={{
          borderWidth: 1,
          borerColor: 'black',
          padding: 10,
          alignSelf: 'stretch',
        }}>
        <Text
          style={{
            textAlign: 'center',
            fontSize: 12,
            marginBottom: 10,
          }}>{`${fields[0].label}: ${fields[0].value}`}</Text>

        <View style={{flexDirection: 'row', justifyContent: 'center'}}>
          <Image
            style={{width: 65, height: 65, marginRight: 20}}
            source={{uri: imgFields[1].data}}
          />
          <View>
            <Text
              style={{
                marginBottom: 2,
                fontSize: 12,
              }}>{`${fields[1].label}: ${fields[1].value}`}</Text>
            <Text
              style={{
                fontSize: 12,
              }}>{`${fields[2].label}: ${fields[2].value}`}</Text>
          </View>
        </View>
      </View>

      <View
        style={{
          alignSelf: 'stretch',
          alignItems: 'flex-start',
          padding: 2,
          borderWidth: 1,
          borerColor: 'black',
          borderStyle: 'dashed',
        }}>
        <Text
          style={{
            fontSize: 12,
          }}>{`${fields[3].label}: ${fields[3].value}`}</Text>
        <Text
          style={{
            fontSize: 12,
          }}>{`${fields[4].label}: ${fields[4].value}`}</Text>
        <Text
          style={{
            fontSize: 12,
          }}>{`${fields[5].label}: ${fields[5].value}`}</Text>
        <Text
          style={{
            fontSize: 12,
          }}>{`${fields[6].label}: ${fields[6].value}`}</Text>
        <Text
          style={{
            fontSize: 12,
          }}>{`${fields[7].label}: ${fields[7].value}`}</Text>
        <Text
          style={{
            fontSize: 12,
          }}>
          {`【注意事項】\n 1. 材積限制：最長邊≦45cm；長+寬+高合計≦105cm；重量≦5cm。\n 2. 請將本單放置於專用袋中並自行實貼於包裹上;\n寄件後無法取消寄件或更換包裹。\n 3. 本單尺寸10cm×14cm 請勿調整尺寸。`}
        </Text>
      </View>
    </View>
  );
}
