import React from 'react';
import * as Admin from 'rev.sdk.js/Actions/Admin';
import * as Cart from 'rev.sdk.js/Actions/Cart';
import * as OrderUtil from 'rev.sdk.js/Utils/OrderUtil';
import {Button, message, Popconfirm} from 'antd';
import * as AppActions from '../../AppActions';
import AdminLinkButton from './AdminLinkButton';

export function handleOrders({path, pageContext}) {
  if (path.indexOf('/admin/orders') !== -1) {
    let firstCol = pageContext.resource.columns[0];
    let targetCollection = pageContext.resource.collection;

    if (targetCollection === 'checkout') {
      pageContext.resource.columns[0] = {
        ...firstCol,
        key: 'order_number',
        dataIndex: 'order_number',
        render: (text, record) => {
          let url = OrderUtil.getAdminUrlFromMergedCheckout(record);
          return (
            <AdminLinkButton
              url={url}
              title={record.order_id || `${record.order_number}*`}
            />
          );
        },
      };
    }

    if (targetCollection === 'order') {
      pageContext.resource.columns[0] = {
        ...firstCol,
        render: (text, record) => {
          return (
            <AdminLinkButton
              url={`/admin/order/?action=detail&id=${record.id}`}
              title={record.order_id || '訂單詳情'}
            />
          );
        },
      };
    }
  }

  if (path === '/admin/orders/waiting') {
    const extraCols = [
      {
        title: '匯款後五碼',
        key: 'offline_tx',
        dataIndex: 'offline_tx',
        customType: 'offline_tx',
        width: 150,
      },
      {
        title: '匯款戶名',
        key: 'offline_username',
        dataIndex: 'offline_username',
        width: 150,
      },
      {
        title: '匯款時間',
        key: 'offline_date',
        dataIndex: 'offline_date',
        width: 150,
      },
    ];

    pageContext.resource.columns = [
      ...pageContext.resource.columns,
      ...extraCols,
    ];

    // note: use delete button to render extra actions
    pageContext.resource.renderDeleteButton = (instance, {refresh}) => {
      if (
        instance.payment_status === Cart.PAYMENT_STATUS.success ||
        instance.payment_subtype !== Cart.PAYMENT_SUBTYPE.offline
      ) {
        return null;
      }
      return (
        <Popconfirm
          title={`已經確認匯款後五碼？${
            instance.offline_tx ? '' : '注意：此筆訂單尚未填寫匯款資訊'
          }`}
          placement="topRight"
          onConfirm={async () => {
            const _hide = message.loading('更新付款狀態...');
            await Admin.confirmOfflineOrder(instance.id);
            await refresh();
            _hide();
          }}>
          <Button type="primary">驗證</Button>
        </Popconfirm>
      );
    };
  }
}
