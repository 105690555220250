import React from 'react';
import {getOutlet} from 'reconnect.js';
import {Button, message} from 'antd';
import {showAdminMultiOrderModal} from '../../Components/AdminMutliOrderModal';

export function MutltiOrderStatusButton(props) {
  const {refresh} = props;
  const title = '修改訂單狀態';

  return (
    <Button
      onClick={() => {
        const orders = getOutlet('selected-orders').getValue();
        if (orders.length <= 0) {
          message.warning('尚未選取訂單。');
          return;
        }

        showAdminMultiOrderModal({title, refresh, name: 'status'});
      }}
      style={{margin: 5}}>
      {title}
    </Button>
  );
}

export function MutltiLogisticsStatusButton(props) {
  const {refresh} = props;
  const title = '修改物流狀態';

  return (
    <Button
      onClick={() => {
        const orders = getOutlet('selected-orders').getValue();
        if (orders.length <= 0) {
          message.warning('尚未選取訂單。');
          return;
        }

        showAdminMultiOrderModal({title, refresh, name: 'logistics_status'});
      }}
      style={{margin: 5}}>
      {title}
    </Button>
  );
}
