import React from 'react';
import {Button, message, InputNumber, Row, Select} from 'antd';
import styled from 'styled-components';
import * as JStorage from 'rev.sdk.js/Actions/JStorage';
import * as Admin from 'rev.sdk.js/Actions/Admin';
import {MoneyDollarCircle} from '@styled-icons/remix-line/MoneyDollarCircle';

const VB_MEMBERSHIP_MAP = {
  level_0: '註冊會員',
  level_1: '微媞之友',
  level_2: '微媞白鑽會員',
  level_3: '微媞粉鑽會員',
};

function VbAdminPrivateProfile(props) {
  const [privateProfile, setPrivateProfile] = React.useState(null);
  const [points, setPoints] = React.useState(0);

  let {context} = props;
  let {instance} = context;

  React.useEffect(() => {
    async function getUserPrivateProfile() {
      let resp = await JStorage.fetchOneDocument('private_profile', {
        owner: instance.owner,
      });

      setPrivateProfile(resp);
      setPoints(resp.points);
    }
    try {
      getUserPrivateProfile();
    } catch (ex) {
      console.warn(ex);
    }
  }, [instance.owner]);

  async function editUserPrivateProfilePoints() {
    try {
      let resp = await Admin.editUserPrivateProfile(instance.owner, points);
      message.success('儲存成功！');
    } catch (ex) {
      console.warn(ex);
    }
  }

  async function editUserPrivateProfileMembershipData() {
    try {
      let resp = await JStorage.updateDocument(
        'private_profile',
        {owner: instance.owner},
        {
          extra: {
            ...privateProfile.extra,
            membership: privateProfile.extra.membership,
            membership_start_date: new Date().getTime(),
            membership_end_date:
              privateProfile.extra.membership === 'level_2' ||
              privateProfile.extra.membership === 'level_3'
                ? new Date().getTime() + 366 * 24 * 60 * 60 * 1000
                : 3229038842236,
          },
        },
      );
      setPrivateProfile(resp);
      message.success('儲存成功！');
    } catch (ex) {
      console.warn(ex);
    }
  }

  if (privateProfile) {
    return (
      <Wrapper
        style={{
          backgroundColor: '#f3f3f3',
          padding: 20,
          marginTop: 10,
          marginBottom: 10,
          borderRadius: 15,
        }}>
        {privateProfile.extra.membership !== undefined && (
          <Row style={{marginBottom: 10, alignItems: 'center'}}>
            <div className="label">會員等級： </div>
            <Select
              value={privateProfile.extra.membership}
              style={{marginRight: 15}}
              onChange={(value) =>
                setPrivateProfile({
                  ...privateProfile,
                  extra: {...privateProfile.extra, membership: value},
                })
              }>
              <Select.Option value="level_0">註冊會員</Select.Option>
              <Select.Option value="level_1">微媞之友</Select.Option>
              <Select.Option value="level_2">微媞白鑽會員</Select.Option>
              <Select.Option value="level_3">微媞粉鑽會員</Select.Option>
            </Select>
            <Button onClick={() => editUserPrivateProfileMembershipData()}>
              儲存
            </Button>
          </Row>
        )}

        {privateProfile.extra.membership_end_date !== undefined && (
          <Row style={{marginBottom: 10, alignItems: 'center'}}>
            <div className="label">會員等級期限：</div>
            <span style={{color: '#b8425f', letterSpacing: 0}}>
              {(privateProfile.extra.membership === 'level_0' ||
                privateProfile.extra.membership === 'level_1') &&
                '永久'}
              {(privateProfile.extra.membership === 'level_2' ||
                privateProfile.extra.membership === 'level_3') &&
                new Date(
                  privateProfile.extra.membership_end_date,
                ).toLocaleDateString('sv')}
            </span>
          </Row>
        )}
        <div style={{display: 'flex', alignItems: 'center', marginBottom: 8}}>
          <p style={{marginRight: 5}}>微媞幣點數</p>
          <MoneyDollarCircle
            size={23}
            style={{marginRight: 5, color: '#b8425f'}}
          />
          <InputNumber
            min={0}
            value={points}
            onChange={(value) => setPoints(value)}
            style={{marginRight: 15}}
          />
          <Button onClick={() => editUserPrivateProfilePoints()}>儲存</Button>
        </div>
      </Wrapper>
    );
  }
  return null;
}

let Wrapper = styled.div`
  & .label {
    margin-right: 10px;
    align-items: center;
    color: rgba(0, 0, 0, 0.85);
    display: inline-flex;
    font-size: 14px;
    height: 32px;
    max-width: 100%;
    position: relative;
  }
`;

export default VbAdminPrivateProfile;
