import React from 'react';
import {
  Document,
  Image,
  Page,
  StyleSheet,
  View,
  Text,
  Font,
} from '@react-pdf/renderer';
import * as ShippingNoteUtil from '../../Utils/ShippingNoteUtil';

export default function AdminShippingNotePdf(props) {
  const {generatedOrders} = props;
  // add an extra padding to make sure we won't exceed the page boundary
  const pageWidth =
    ShippingNoteUtil.shippingNoteImgSize.width + 3 * pagePadding;
  const pageHeight =
    ShippingNoteUtil.shippingNoteImgSize.height + 3 * pagePadding;

  return (
    <Document>
      {generatedOrders.map((order, idx) => {
        return (
          <React.Fragment key={idx}>
            <Page size={[pageWidth, pageHeight]} style={styles.page}>
              <View style={styles.section}>
                {order.data?.is711 ? (
                  <ShippingNoteUtil.PdfView711 data={order.data} />
                ) : (
                  <Image style={styles.img} source={{uri: order.data}} />
                )}
              </View>
            </Page>
            <Page size={[pageWidth, pageHeight]} style={styles.page}>
              <View style={styles.section}>
                <View style={styles.topView}>
                  <View style={[styles.container, styles.haveMarginBottom]}>
                    <Text style={styles.text}>
                      訂單編號：{order.order.order_id}
                    </Text>
                    <Text style={styles.text}>
                      物流單編號：{order.order.logistics_id}
                    </Text>
                  </View>

                  <View style={styles.table}>
                    <View style={[styles.tr, styles.haveBorderBottom]}>
                      {['名稱', '規格', '數量'].map((label) => (
                        <View style={styles.td}>
                          <Text style={styles.text}>{label}</Text>
                        </View>
                      ))}
                    </View>
                    {order.order.items.map((item) => {
                      return (
                        <View style={[styles.tr, styles.haveBorderBottom]}>
                          <View style={styles.td}>
                            <Text style={styles.text}>{item.name}</Text>
                          </View>
                          <View style={[styles.td]}>
                            {!item.config.variants ||
                            item.config.variants.length <= 0 ? (
                              <Text style={styles.text}>一般</Text>
                            ) : (
                              item.config.variants.map((variant) => {
                                if (variant.name && variant.choice) {
                                  return (
                                    <Text style={styles.text}>
                                      {variant.name} - {variant.choice}
                                    </Text>
                                  );
                                }

                                return (
                                  <Text style={styles.text}>
                                    {variant.name}
                                  </Text>
                                );
                              })
                            )}
                          </View>
                          <View style={styles.td}>
                            <Text style={styles.text}>×{item.config.qty}</Text>
                          </View>
                        </View>
                      );
                    })}
                    <View style={[styles.tr]}>
                      <View
                        style={[styles.td, {maxWidth: styles.td.maxWidth * 3}]}>
                        <Text style={styles.text}>
                          備註：{order.order.order_note}
                        </Text>
                      </View>
                    </View>
                  </View>
                </View>
              </View>
            </Page>
          </React.Fragment>
        );
      })}
    </Document>
  );
}

Font.register({
  family: 'MiSans',
  src: '/fonts/MiSans-Normal.ttf',
});

const pagePadding = 20;

const styles = StyleSheet.create({
  page: {
    fontFamily: 'MiSans',
    flexDirection: 'column',
    backgroundColor: '#fff',
    padding: pagePadding,
  },
  section: {
    flexGrow: 1,
    alignSelf: 'stretch',
    borderWidth: 1,
    borderColor: '#ccc',
  },
  img: {
    width: ShippingNoteUtil.shippingNoteImgSize.width,
    height: ShippingNoteUtil.shippingNoteImgSize.height,
    objectFit: 'contain',
  },
  topView: {
    position: 'absolute',
    top: '0%',
    left: 0,
    padding: 30,
  },
  container: {},
  text: {
    fontSize: 12,
    color: '#000000',
  },
  table: {
    width: 300,
  },
  tr: {
    display: 'flex',
    flexDirection: 'row',
  },
  td: {
    maxWidth: 100,
    width: '100%',
    padding: 5,
  },
  haveMarginBottom: {
    marginBottom: 5,
  },
  haveBorderBottom: {
    borderBottom: '1px solid #CBCBCB',
  },
});
