import React from 'react';
import {Form, Select, Input, Button, message} from 'antd';
import styled from 'styled-components';
import {useOutlet} from 'reconnect.js';
import AntdAddressSetForm from 'rev.sdk.js/Components/AntdAddressSetForm';
import * as Cart from 'rev.sdk.js/Actions/Cart';
import * as JStorage from 'rev.sdk.js/Actions/JStorage';
import Config from '../../../../data.json';
import * as AppActions from '../../../AppActions';

function UpdateOrderSection(props) {
  const defaultCheckoutFormSpec = AppActions.getDefaultCheckoutFormSpec();
  const {
    context: {instance, values, setValues},
  } = props;
  const [form] = Form.useForm();
  const [isDirty, setIsDirty] = React.useState(false);
  const [actions] = useOutlet('actions');
  const [disabled, setDisabled] = React.useState(false);

  React.useEffect(() => {
    if (!values) {
      return;
    }
    form.setFieldsValue({
      receiver_name: values.receiver_name,
      receiver_phone: values.receiver_phone,
      receiver_address: values.receiver_address,
      receiver_zip: values.receiver_zip,
      receiver_city: values.receiver_city,
      receiver_district: values.receiver_district,
      logistics_type: values.logistics_type,
      logistics_subtype: values.logistics_subtype,
      logistics_cvs_store_id: values.logistics_cvs_store_id,
    });
  }, [form, values]);

  const handleUpdateOrder = React.useCallback(
    async (data) => {
      try {
        actions.setLoading(true);
        const payload =
          data.logistics_type === Cart.LOGISTICS_TYPE.home
            ? {
                receiver_name: data.receiver_name,
                receiver_phone: data.receiver_phone,
                logistics_type: data.logistics_type,
                logistics_subtype: data.logistics_subtype,
                receiver_address: data.receiver_address,
                receiver_zip: data.receiver_zip,
                receiver_city: data.receiver_city,
                receiver_district: data.receiver_district,
                logistics_cvs_store_id: '',
                extra_data: {},
              }
            : {
                receiver_name: data.receiver_name,
                receiver_phone: data.receiver_phone,
                logistics_type: data.logistics_type,
                logistics_subtype: data.logistics_subtype,
                logistics_cvs_store_id: data.logistics_cvs_store_id,
                extra_data: {
                  CVSAddress: data.CVSAddress,
                  CVSOutSide: data.CVSOutSide,
                  CVSStoreID: data.logistics_cvs_store_id,
                  CVSStoreName: data.CVSStoreName,
                  CVSTelephone: data.CVSTelephone,
                  MerchantTradeNo: data.MerchantTradeNo,
                },
              };

        await JStorage.updateDocument('order', {id: values.id}, payload);

        setValues((prev) => ({
          ...prev,
          ...payload,
        }));
        setIsDirty(false);
        message.success('更新資訊成功');
      } catch (e) {
        message.error('更新資訊失敗');
      } finally {
        actions.setLoading(false);
      }
    },
    [actions, setValues, values.id],
  );

  if (!values) {
    return null;
  }

  return (
    <Wrapper>
      <Form
        layout="horzonital"
        form={form}
        initialValues={{
          receiver_address: values.receiver_address,
          receiver_zip: values.receiver_zip,
          receiver_city: values.receiver_city,
          receiver_district: values.receiver_district,
          logistics_type: values.logistics_type,
          logistics_subtype: values.logistics_subtype,
          logistics_cvs_store_id: values.logistics_cvs_store_id,
          // extra_data object
          CVSAddress: values.extra_data.CVSAddress,
          CVSOutSide: values.extra_data.CVSOutSide,
          CVSStoreID: values.extra_data.logistics_cvs_store_id,
          CVSStoreName: values.extra_data.CVSStoreName,
          CVSTelephone: values.extra_data.CVSTelephone,
          MerchantTradeNo: values.extra_data.MerchantTradeNo,
        }}
        onFinish={async (data) => {
          await handleUpdateOrder(data);
        }}
        onFinishFailed={() => {}}
        onFieldsChange={(field) => {
          if (field[0].name[0] === 'logistics_type') {
            form.setFieldsValue({
              logistics_subtype: '',
            });
          }
          const _isDirty = Object.keys(form.getFieldsValue()).some(
            (key) => form.getFieldsValue()[key] !== values[key],
          );
          setIsDirty(_isDirty);
        }}>
        <Form.Item label="收件人姓名" name="receiver_name">
          <Input placeholder="收件人姓名" />
        </Form.Item>
        <Form.Item label="收件人手機" name="receiver_phone">
          <Input placeholder="收件人手機" />
        </Form.Item>
        <Form.Item
          rules={[{required: true, message: '必填'}]}
          name="logistics_type"
          label="物流方式">
          <Select>
            <Select.Option key="-1" value="" disabled={true}>
              請選擇物流方式
            </Select.Option>
            {Object.values(Cart.LOGISTICS_TYPE_DISPLAY).map((opt, idx) => (
              <Select.Option key={idx} value={opt.value}>
                {opt.label}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item dependencies={['logistics_type']}>
          {(instance) => {
            const selectLogisticsSubTypeElem = (
              <Form.Item
                rules={[{required: true, message: '必填'}]}
                label="物流商"
                name="logistics_subtype">
                <Select>
                  <Select.Option key="-1" value="" disabled={true}>
                    請選擇物流商
                  </Select.Option>

                  {instance.getFieldValue('logistics_type') ===
                  Cart.LOGISTICS_TYPE.cvs
                    ? defaultCheckoutFormSpec.logisticsSubTypes[
                        Cart.LOGISTICS_TYPE.cvs
                      ].map((value, idx) => {
                        let opt = Cart.LOGISTICS_SUBTYPE_DISPLAY[value];

                        return (
                          <Select.Option key={idx} value={opt.value}>
                            {opt.label}{' '}
                            {opt.value.toUpperCase().indexOf('C2C') < 0 &&
                              '大宗'}
                          </Select.Option>
                        );
                      })
                    : defaultCheckoutFormSpec.logisticsSubTypes[
                        Cart.LOGISTICS_TYPE.home
                      ].map((value, idx) => {
                        let opt = Cart.LOGISTICS_SUBTYPE_DISPLAY[value];
                        return (
                          <Select.Option key={idx} value={opt.value}>
                            {opt.label}
                          </Select.Option>
                        );
                      })}
                </Select>
              </Form.Item>
            );

            return instance.getFieldValue('logistics_type') ===
              Cart.LOGISTICS_TYPE.cvs ? (
              <>
                <Form.Item>
                  <Button
                    htmlType="button"
                    onClick={() => {
                      window.open(
                        `${Config.webHost}/admin/select-cvs`,
                        '_blank',
                      );
                    }}>
                    查詢超商資訊
                  </Button>
                </Form.Item>
                {selectLogisticsSubTypeElem}
                <Form.Item
                  rules={[{required: true, message: '必填'}]}
                  label="超商ID (CVSStoreID)"
                  name="logistics_cvs_store_id">
                  <Input placeholder="請將超商 ID 的 CVSStoreID 欄位值複製貼上於此" />
                </Form.Item>
                <Form.Item
                  rules={[{required: true, message: '必填'}]}
                  label="超商分店名稱（CVSStoreName）"
                  name="CVSStoreName">
                  <Input placeholder="請將超商分店名稱的 CVSStoreName 欄位值複製貼上於此" />
                </Form.Item>
                <Form.Item
                  rules={[{required: true, message: '必填'}]}
                  label="超商地址（CVSAddress）"
                  name="CVSAddress">
                  <Input placeholder="請將超商地址的 CVSAddress 欄位值複製貼上於此" />
                </Form.Item>
              </>
            ) : (
              <>
                {selectLogisticsSubTypeElem}
                <AntdAddressSetForm form={form} name="receiver" />
              </>
            );
          }}
        </Form.Item>

        <Form.Item>
          <Button
            disabled={!isDirty}
            onClick={form.submit}
            type="primary"
            ghost
            htmlType="button"
            style={{marginRight: 10}}>
            更新資訊
          </Button>
        </Form.Item>
      </Form>
    </Wrapper>
  );
}

const Wrapper = styled.div``;

export default UpdateOrderSection;
