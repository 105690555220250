import * as CatUtil from '../../Utils/CatUtil';

export function handleArticles({path, pageContext}) {
  if (path.indexOf('/admin/articles') > -1) {
    const cats = CatUtil.getFlattenCategories('articleCategories');

    pageContext.resource.querySpec.filters[0].options = [
      {value: 'blog', name: 'blog'},
      ...cats.map((cat) => ({value: cat.name, name: cat.display})),
    ];

    pageContext.resource.formSpec.schema.properties.labels.items = {
      type: 'string',
      enum: ['blog', ...cats.map((cat) => cat.name)],
      enumNames: ['blog', ...cats.map((cat) => cat.display)],
    };
  }

  if (path.indexOf('/admin/i18n-articles') > -1) {
    const cats = CatUtil.getFlattenCategories('articleCategories');

    pageContext.resource.querySpec.filters[0].options = [
      {value: 'blog', name: 'blog'},
      ...cats.map((cat) => ({value: cat.name, name: cat.display})),
    ];

    pageContext.resource.formSpec.schema.properties.labels.items = {
      type: 'string',
      enum: ['blog', ...cats.map((cat) => cat.name)],
      enumNames: ['blog', ...cats.map((cat) => cat.display)],
    };
  }
}
