import React from 'react';
import AdminLinkButton from './AdminLinkButton';
import AdminProductStockActionButton from './AdminProductStockActionButton';

export function handleStocks({path, pageContext}) {
  if (path === '/admin/stocks') {
    const firstCol = pageContext.resource.columns[0];
    pageContext.resource.columns[0] = {
      ...firstCol,
      render: (text, record) => {
        const url = `/admin/stocks/?action=detail&id=${record.id}`;

        return (
          <AdminLinkButton
            url={url}
            title={`${record.name}${
              record.session && Object.keys(record.session).length > 0
                ? ` ${record.session.date} ${record.session.start_time}-${record.session.end_time}`
                : ``
            }`}
          />
        );
      },
    };

    pageContext.resource.renderDetailButton = (record, clientCallbacks) => {
      if (!record.stock_type || record.stock_type === 'always') {
        return '-- --';
      }

      return (
        <AdminProductStockActionButton
          record={record}
          refresh={clientCallbacks.refresh}
        />
      );
    };
  }

  return null;
}
